<template>
  <div class="application-detail">
    <div class="navigation-bar" v-if="this.appDetail">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/application/market' }" replace>应用市场</el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ this.appDetail.detail.name }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="application-detail" v-if="this.appDetail">
      <div class="description">
        <el-row>
          <el-col :span="4">
            <el-image v-if="appDetail.detail.coverImgUrl" :src="appDetail.detail.coverImgUrl" />
          </el-col>
          <el-col :span="20" style="padding-left: 1rem">
            <h3>
              {{ this.appDetail.detail.name }}
            </h3>
            <p>
              {{ this.appDetail.detail.description }}
            </p>
            <p>
              <el-tag v-if="this.appDetail.subscribeFlag === 1" size="mini" effect="dark" type="success">订阅中</el-tag>
              <el-tag v-if="this.appDetail.subscribeFlag === 0" size="mini" effect="dark" type="info">未订阅</el-tag>
            </p>
          </el-col>
        </el-row>
      </div>

      <div class="param-defines">
        <div v-if="this.appDetail.subscribeFlag === 1">
          <p class="title">应用配置</p>
          <table class="config-table" cellspacing="1" cellpadding="0">
            <tr v-for="item in paramDefines" :key="item.fieldName">
              <td class="label">{{ item.label }}</td>
              <td>
                <input v-if="item.fieldType === 1" style="width: 100%; border: 0px;margin: 0px;"
                  v-model="paramDefinValue[item.fieldName]" />
                <textarea v-if="item.fieldType === 2" style="width: 100%; border: 0px;margin: 0px;" rows="3"
                  v-model="paramDefinValue[item.fieldName]"></textarea>
              </td>
            </tr>
          </table>
        </div>

        <div class="bottom-bar">
          <el-button v-if="this.appDetail.subscribeFlag === 0" size="mini" type="primary"
            @click="subscribeApplication">订阅应用</el-button>
          <el-button v-if="this.appDetail.subscribeFlag === 1" size="mini" type="primary"
            @click="saveParamDefineValues">保存配置</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "application-detail",
  data() {
    return {
      appId: null,
      appDetail: null,
      paramDefinValue: {},
      paramDefines: []
    };
  },
  computed: {
  },
  methods: {
    showLoading() {
      return this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    async subscribeApplication() {
      const loading = this.showLoading();
      try {
        await this.$http.doApi("fw-app-market-subscribeApplication", {
          id: this.appId,
        });
        this.$message.success("订阅成功");
        this.loadApplicationDetail();
      } catch (e) {
        console.log(e);
      } finally {
        loading.close();
      }
    },
    async saveParamDefineValues() {
      const loading = this.showLoading();
      try {
        await this.$http.doApi(
          "fw-app-market-saveParamDefineValues",
          {
            id: this.appId,
          },
          this.paramDefinValue
        );
        this.$message.success("配置保存成功");
      } catch (e) {
        console.log(e);
      } finally {
        loading.close();
      }
    },
    async loadApplicationDetail() {
      const loading = this.showLoading();
      try {
        this.appDetail = await this.$http.doApi("fw-app-market-wx-app-detail", {
          id: this.appId,
        });
        this.paramDefines = this.appDetail.paramDefines;
        if (this.appDetail.wxEventHandler) {
          this.paramDefinValue = JSON.parse(this.appDetail.wxEventHandler.params);
        }
      } catch (e) {
        console.log(e);
      } finally {
        loading.close();
      }
    },
  },
  created() {
    this.appId = this.$route.params.id;
    this.loadApplicationDetail();
  },
};
</script>

<style lang="less">
.application-detail {
  .navigation-bar {
    padding: 1rem;
    border-bottom: @hair-border-ccc;
  }

  .application-detail {
    padding: 1rem;

    .description {
      border-bottom: @hair-border-ccc;
    }

    .param-defines {
      padding: 1rem;

      .title {
        margin: 0px;
        margin-bottom: 1rem;
        padding-bottom: .5rem;
        border-bottom: @hair-border-ccc;
      }

      .config-table {
        width: 100%;
        margin: 0px auto;
        background-color: #CFCFCF;

        td {
          background-color: #FFFFFF;
        }

        .label {
          width: 15%;
          background-color: #FFFFFF;
          padding: 5px;
        }
      }
    }

    .bottom-bar {
      text-align: center;
    }
  }
}
</style>